import 'element-internals-polyfill'
import 'iconify-icon'
import 'webcomponent-qr-code'
import 'bootstrap'
import '@sonicgarden/native-datepicker-element'
import '@sonicgarden/bs-hovercard-element'
import '@sonicgarden/autosize-textarea-element'
import '@sonicgarden/auto-submit-form-element'
import '@sonicgarden/webauthn-elements'
import { start } from '@sonicgarden/rails-ujs-compat'
import { setupLib } from '@/lib'

start()
setupLib()
