/* eslint no-param-reassign: ["error", { "props": false }] */
// NOTE: railsの date_selectが細かい表示調整しにくいので無理やりjsで

const NAME_PATTERN = /\(([13])i\)]$/

const appendUnit = (element: HTMLSelectElement, unit: string) => {
  for (const option of element.options) {
    if (option.textContent && option.textContent.length > 0) {
      option.textContent = `${option.textContent}${unit}`
    }
  }
}

const init = () => {
  for (const element of document.querySelectorAll<HTMLSelectElement>('select.date, select.datetime')) {
    const matches = element.getAttribute('name')?.match(NAME_PATTERN)
    if (!matches) {
      continue
    }
    const [, indexStr] = matches

    switch (indexStr) {
      case '1':
        appendUnit(element, '年')
        break
      case '3':
        appendUnit(element, '日')
        break
      default:
        throw new Error('invalid indexStr')
    }
  }
}

export const setupDateFormatter = () => {
  document.addEventListener('DOMContentLoaded', init)
}
